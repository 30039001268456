.btn{
    @apply h-12 font-bold text-center px-8 rounded-full inline-block transition-all;
    .icon{
        @apply mr-3;
    }
    span{
        vertical-align: middle;
    }
}
.btn-primary{
    @apply bg-custom-yellow hover:bg-[#f2b90d] text-custom-blue-darken;
}
.btn-gray{
    @apply bg-custom-gray hover:bg-[#c7c7c7] text-custom-blue-darken;
}
.btn-outline-gray{
    @apply text-custom-blue-darken border border-[#A7AAB3] hover:bg-custom-blue-darken hover:bg-opacity-5;
}
.btn-outline-light{
    @apply text-white border hover:bg-white hover:bg-opacity-10;
}
