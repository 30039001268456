.slick-slider{
    @apply select-text;
    .slick-list{
        @apply transition-all duration-500;
        .slick-slide{
            @apply -z-10;
            &.slick-active{
                @apply z-10;
            }
        }
    }
}
